.dinamicInputContainer {
  margin-top: 24px;

  .title {
    margin-bottom: 8px;
  }

  .pricesTable {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 0.1fr;
    grid-column-gap: 10px;
    border: 1px solid #cccccc;
    padding: 8px;
    margin-bottom: 16px;
    align-items: baseline;
  }

  .btnText {
    margin-top: 8px;
  }
}