.header {
  background-color: #fafafa;
  font-weight: 500;

  .headerCell {
    padding: 16px;
    border: 1px solid #e8e8e8;
    border-spacing: 0
  }
}

.virtualTableCell {
  box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    background: #FFF;
}

// .virtualTableCellLast {

// }