.salesTable {
  & thead > tr > th {
    text-align: center;
    &:nth-child(1) {
      background-color: #dbd5f9;
    }
    &:nth-child(2) {
      background-color: #b3cbf9;
    }
    &:nth-child(3) {
      background-color: #ade1f9;
    }
    &:nth-child(4) {
      background-color: #f4b7bc;
    }
    &:nth-child(5) {
      background-color: #f3d1ac;
    }
    &:nth-child(6) {
      background-color: #a7d6ac;
    }
    &:nth-child(7) {
      background-color: #c3c9ad;
    }
    &:nth-child(8) {
      background-color: #e5ebc7;
    }
  }
}