.container {
  margin-bottom: 24px;

  .title {
    font-size: medium;
    margin-bottom: 8px;
  }

  img {
    width: 100%;
  }

  .btnText {
    margin-top: 8px;
  }
}