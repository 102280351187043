.container {
  text-align: center;

  .imageContainer {
    height: 30%;

    img {
      height: 100px;
    }
  }
}