.formItem {
  margin: 0 !important;
  font-size: 12px;

  & > div.ant-col.ant-form-item-label {
    padding: 0 !important;
  }

  .inputNumber {
    margin-top: -18px !important;
    font-size: 12px;

    input {
      height: 20px;
    }
  }
}

.label {
  font-size: 12px !important;
}