@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.site-layout {
  background-color: #e9e9e9;
}

.ant-menu.ant-menu-dark,
.ant-layout-header,
.ant-layout-sider.ant-layout-sider-dark,
.ant-layout.ant-layout-has-sider,
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #014a74;
}

.ant-divider {
  border-top: 1px solid #014a74;
}

.ant-layout-header {
  padding: 0 25px;
}

.logo {
  font-weight: bold;
  font-size: large;
  color: white;
}

.logo a {
  color: white;
}

.page-header {
  padding: 0 !important;
}

/* .ant-page-header-heading-extra {
  position: fixed;
  right: 24px;
  z-index: 2;
} */

.content-layout {
  padding: 0 24px 24px;
  /* margin-left: 150px; */
  transition: all 0.2s;
  width: 100%;
}

.content-layout-collapse {
  /* margin-left: 80px; */
  transition: all 0.2s;
}

.mg-t-16 {
  margin-top: 16px;
}

.mg-b-16 {
  margin-bottom: 16px;
}

.page-content {
  height: 90vh;
  overflow: scroll;
  background-color: white;
  border-radius: 10px;
  padding: 8px 16px;
}

.even-row {
  background-color: #f0f0f0;
  border-bottom: 2px solid grey;
  border-top: 2px solid grey;
}

.ant-table table {
  border-collapse: collapse;
}