@import "../../../../variables.scss";

.actionBtn {
  font-size: 24px;

  &:hover {
    color: $main-color;
  }
}

.disabled {
  color: lightgray;
  cursor: default;

  &:hover {
    color: lightgray;
  }
}

.plus {
  margin-bottom: 2px;
}

.counterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  margin-right: 16px;
}

.counter {
  display: flex;
  justify-content: space-between;
  min-width: 55px;
  margin-bottom: 4px;
}

.manualCounter {
  display: flex;
  justify-content: end;
  min-width: 55px;
}

.countTitle {
  line-height: 30px;
}

.inputCount {
  margin-left: 8px;
  margin-bottom: 4px;
}

.count {
  font-size: 20px;
  color: black;
  line-height: 24px;
  margin: 0 8px;
}