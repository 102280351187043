.labelField {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;

  label {
    display: inline-block;
  }

  span {
    display: inline-block;
  }
}