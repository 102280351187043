@import "../../../variables.scss";

.statusSelect {
  width: 200px;
}

.search {
  width: 100%;
}

.productsContainer {
  margin-top: $margin-xl;
}

.actionBtn {
  font-size: large;

  &:hover {
    color: $main-color;
  }
}

.plus {
  margin-bottom: 2px;
}

.counter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summary {
  display: flex;
  justify-content: space-between;
  margin-top: $margin-default;
  border-top: 1px solid grey;
  padding-top: $padding-xs;

  .discount {
    width: 130px;
    margin-left: $margin-xs;
  }
}