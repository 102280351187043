// COLOR
$main-color: #014a74;

// MARGIN
$margin-xs: 8px;
$margin-default: 16px;
$margin-xl: 24px;

// PADDING
$padding-xs: 8px;
$padding-default: 16px;
$padding-xl: 24px