@import '../../variables.scss';

.advanceSearchForm {
  margin-bottom: 1em;
}

.selectBefore {
  min-width: 150px;
  max-width: 170px;
  height: 30px;
}

.actionButton {
  text-align: center;
}